@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Page Top
// ==================================================
.page-top {
  @include mixin.pc {
    padding-top: 168px;
  }

  // Footer
  & + .footer {
    margin-top: 107px;

    @include mixin.sp {
      margin-top: 32px;
    }
  }

  // Mainvisual
  .mainvisual {
    display: block;
    width: 100%;

    &_message {
      display: block;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 54px 0 80px;

      @include mixin.sp {
        max-width: calc(100% - 60px);
        padding: 36px 0 38px;
      }

      &_01 {
        font-size: 3.6rem;
        line-height: calc(52 / 36);
        font-weight: 700;
        letter-spacing: 1.8px;
        margin: 0;

        @include mixin.sp {
          font-size: 3rem;
          line-height: calc(43 / 30);
          letter-spacing: 1.5px;
        }
      }

      &_02 {
        display: block;
        width: 100%;
        max-width: 299px;
        margin: 16px 0 0;

        @include mixin.sp {
          max-width: 219px;
          margin-top: 21px;
        }
      }
    }

    &_slider {
      &_item {
        display: block;
        width: 700px;

        @include mixin.sp {
          width: 382px;
        }
      }

      // Javascript
      &.js-mainvisualSlider {
      }
    }
  }

  // Feature
  .feature {
    display: block;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    padding: 138px 0 144px;

    @include mixin.sp {
      max-width: calc(100% - 60px);
      padding: 50px 0 89px;
    }

    &_title {
      font-size: 3.6rem;
      line-height: calc(52 / 36);
      font-weight: 700;
      letter-spacing: 1.8px;
      margin: 0 0 50px;

      @include mixin.sp {
        font-size: 2.2rem;
        line-height: calc(32 / 22);
        letter-spacing: 1.1px;
        margin-bottom: 30px;
      }
    }

    &_description {
      font-size: 1.6rem;
      line-height: calc(37 / 16);
      font-weight: 500;
      margin: 16px 0 0;

      @include mixin.sp {
        font-size: 1.4rem;
        line-height: calc(30 / 14);
        margin-top: 14px;
      }
    }

    &_link {
      display: block;
      width: 100%;
      max-width: 199px;
      font-size: 1.8rem;
      line-height: calc(26 / 18);
      font-weight: 500;
      position: relative;
      padding-right: 44px;
      margin-top: 33px;
      color: inherit;
      text-decoration: none;

      @include mixin.sp {
        max-width: 172px;
        font-size: 1.5rem;
        line-height: calc(22 / 15);
        padding-right: 43px;
        margin-top: 32px;
      }

      &_inner {
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: -4px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: color.$site-write;
          transform: scale(0, 1);
          transition: transform 0.3s ease;
          transform-origin: top right;
        }
      }

      &::before,
      &::after {
        content: "";
        display: block;
        width: 33px;
        height: auto;
        aspect-ratio: 1/1;
        background-color: color.$site-write;
        border-radius: 2px;
        position: absolute;
        top: calc(50% + 1px);
        right: 1px;
        transform: translateY(-50%);
        transition: right 0.3s ease;

        @include mixin.sp {
          width: 27px;
        }
      }

      &::after {
        background-color: transparent;
        background-image: url(../img/common/ico_arrow.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 14px auto;

        @include mixin.sp {
          background-size: 12px auto;
        }
      }

      @include mixin.hover {
        .feature_link_inner {
          &::after {
            transform: scale(1, 1);
            transform-origin: top left;
          }
        }

        &::after {
          // right: -3px;
          animation-name: animation_arrow_toright;
          animation-duration: 0.5s;
          animation-timing-function: ease;
        }
      }
    }
  }
}
